import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class LaudoInspacaoService {

    getCodigoItem(safra, ordemCampo, nroLaudo, codigoEstabelecimento, codigoGrupoProduto){
        return axios.get(`${ ApiUrl.portal_url }api/pa/v1/semente/produtor/buscar-laudo-inspecao-por/codigo-estabelecimento/${ codigoEstabelecimento }/safra/${ safra }/codigo-grupo-produto/${ codigoGrupoProduto }/ordem-campo/${ ordemCampo }/numero-laudo/${ nroLaudo }`);
    }

    buscarLaudoPor(
        safra, codigoProduto, codigoGrupoProduto, codigoEstabelecimento, matricula, nrOrdemCampo
    ) {
        return axios.get(`${ ApiUrl.portal_url }api/pa/v1/semente/campo-laudo/buscar-por/safra/${ safra }/codigo-produto/${ codigoProduto }/codigo-grupo-produto/${ codigoGrupoProduto }/codigo-estabelecimento/${ codigoEstabelecimento }/matricula/${ matricula }/nr-ordem-campo/${ nrOrdemCampo }`);
    }
}

export default new LaudoInspacaoService();