<template>
    <LaudoInspecaoCampo/>
</template>

<script lang="js">
    import LaudoInspecaoCampo from '../../components/atividade/LaudoInspacaoCampo.vue'
    export default {
        components: { LaudoInspecaoCampo },

        data(){
            return {}
        },

        methods: {
            
        },

        created() {
            
        },   
    }
</script>